.layout {
	display: flex;
	flex-direction: column;

	.navbar {
		.appVersion {
			font-size: .6em;
			font-style: italic;
			opacity: .2;
		}

		.nav-link.dropdown-toggle {
			color: rgba(white, 0.5);

			&:hover, &:active {
				color: rgba(white, 0.7);
			}
		}
	}

	#mainRegion {
		flex: 1 1 auto;
		background-color: white;
		padding-top: 1em;
		padding-bottom: 1em;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}
}
