.home {
	display: flex;
	flex-direction: column;

	#mainRegion {
		// padding: 2em;
		// padding-top: 1em;
		// padding-left: 1em;
	}

	.sectionList {
		display: flex;
		align-items: flex-start;
	}

	.sectionButton {
		// padding: 1em;
		// background-color: lightgray;
		// border-radius: .5em;

		margin-top: 1em;
		margin-right: 1em;

		&.gm {
			background-color: RebeccaPurple;
			border-color: RebeccaPurple;

			&:hover, &:active {
				background-color: BlueViolet;
				border-color: BlueViolet;
			}
		}

		&.gmkids {
			background-color: DarkSlateGrey;
			border-color: DarkSlateGrey;

			&:hover, &:active {
				background-color: DarkOliveGreen;
				border-color: DarkOliveGreen;
			}
		}
	}
}
