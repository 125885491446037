
.login {
	display: flex;
	align-items: center;
	justify-content: center;

	.loginBox {
		display: block;
		width: 20em;

		background-color: #f5f5f5;
		padding: 1em;
		border-radius: .3em;
		box-shadow: 3px 3px 7px rgba(0,0,0,0.3);

		h1 {
			margin-bottom: .5em;
		}

		.messageZone {
			color: red;
		}

		input {
			margin-top: .5em;
		}

		.controls {
			margin-top: .5em;
			display: flex;
			align-items: flex-end;
		}
	}

	.details {
		position: absolute;
		bottom: .5em;
		font-style: italic;
		font-size: .8em;
		color: rgba(white,.2);
	}
}
