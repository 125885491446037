@import 'toastr/toastr';

@import 'bootstrap/dist/css/bootstrap.css';


$fa-font-path: "../../../../node_modules/fontawesome-pro/webfonts";

@import 'fontawesome-pro/scss/fontawesome';
@import 'fontawesome-pro/scss/solid';
@import 'fontawesome-pro/scss/regular';

@import '../../common/styles/login';
@import 'layout';
@import 'home';
@import 'admin';
@import 'gamemaster';
@import 'common';

body {
	height: 100vh;
}

#container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

#container > :not(spinner) {
	flex: 1 1 auto;
}

.spacer {
	flex: 1 1 auto;
}

.center {
	text-align: center;
}

.vcenter {
	vertical-align: middle;

	td, th {
		vertical-align: middle;
	}
}

.novpadding.novpadding.novpadding.novpadding.novpadding.novpadding.novpadding {
	padding-top: 0;
	padding-bottom: 0;
}

.nohpadding.nohpadding.nohpadding.nohpadding.nohpadding.nohpadding.nohpadding {
	padding-left: 0;
	padding-right: 0;
}

.flexSpacer {
	flex: 1 1 auto;
}

progress.longpress {
	width: 3em;
}

.longpressSource {
	transition: box-shadow 1s;

	&.flash {
		transition: none;
		box-shadow: 0 0 1em .5em blue;
	}
}
