.gameList {
	#dragOverlay {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(black,.5);
		z-index: 100000;

		opacity: 0;

		transition: opacity .1s;
		pointer-events: none;

		&.visible {
			opacity: 1;
			pointer-events: auto;
		}

		.dropContainer {
			position: relative;
			margin: 5vh 5vw;
			height: 90vh;
			width: 90vw;

			border-radius: 30px;
			border: dashed .5em #aaa;
			background-color: rgba(white,.9);

			pointer-events: none; // Avoid dragOverlay content to send drag events

			.dropMessage {
				$width: 400px;
				$height: 300px;

				position: absolute;
				left: 50%;
				top:50%;
				margin-top: -$height*.5;
				margin-left: -$width*.5;
				width: $width;
				height: $height;

				color: #aaa;

				text-align: center;

				.icon {
					font-size: 10em;
				}

				.text {
					font-size: 3em;
				}
			}
		}
	}
}
