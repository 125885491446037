
.deviceList {
	.message {
		opacity: .5;
		font-style: italic;
	}

	.nameInput {
		width: 100%;
		background-color: transparent;
		border-color: transparent;

		&:focus {
			background-color: white;
			border-color: #eee;
		}
	}

	.device {
		&.connected {
			.connectionIndicator {
				color: limegreen;
			}
		}

		&.deleted {
			opacity: .5;
		}

		.connectionIndicator {
			color: red;
		}
	}

	.deviceIcons {
		max-width: 4rem;
		white-space: nowrap;
	}

	.osVersion {
		max-width: 5rem;
	}

	.actions {
		max-width: 13rem;
		white-space: nowrap;
	}
}
