.devicePicker {
	.modal-body {
		padding: 0;
	}

	.device {
		padding: 1rem;

		.connectionIndicator {
			color: red;
		}

		&.connected .connectionIndicator {
			color: limeGreen;
		}

		&+.device {
			border-top: solid 1px #dee2e6;
		}

		.model {
			opacity: .2;
		}
	}
}
