.deviceMessages {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;

	.state {
		&.connected {
			.indicator {
				color: limegreen;
			}
		}

		.indicator {
			color: red;
		}
	}

	#messageRegion {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;

		.messagingConsole {
			flex: 1 1 auto;
		}
	}
}
