.messagingConsole {
	height: 100%;
	display: flex;
	flex-direction: column;

	form {
		display: flex;
		margin-top: 1em;

		.messageInput {
			flex: 1 1 auto;
		}

		.submitButton {
			flex: 0 0 auto;
			margin-left: .5em;
		}
	}

	.log {
		flex: 1 1 auto;
		height: 10vh; // Must specify a starting height or content will always overflow.
		overflow-x: auto;
		overflow-y: scroll;
		padding: .5em;
		background-color: #eee;

		display: flex;
		align-items: flex-start;

		.message {
			padding: .5em 1em;
			border-radius: .5em;
			color: white;

			&.inbound {
				background-color: DodgerBlue;
			}

			&.outbound {
				align-self: flex-end;
				background-color: LimeGreen;
			}

			&+.message {
				margin-top: .5em;
			}
		}
	}
}
