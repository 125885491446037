
.sessionDetails {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.card-header {
		display: flex;
		align-items: center;
		gap: .6rem;
	}

	#idLabel {
		opacity: .2;
	}

	.messageContainer {
		height: 17rem;
	}

	.controlButton {
		display: none;
		font-size: 1rem;
		margin: 0;
	}

	#timeContainer {
		padding: 0;
		line-height: 95%;
		font-size: 8em;
		font-family: monospace;
	}

	.timeContainer {
		h5 {
			height: 2.5em;
			padding-top: 0;
			padding-bottom: 0;

			button {
				margin-left: 1em;
			}
		}
	}

	#controls {
		display: flex;
		align-items: center;
		gap: .5em;
	}

	#statusDisplay {
		flex: 1 1 auto;
		color: rgba(black,.5);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.sessionDeviceList {
	display: flex;
	gap: .5rem;

	.deviceContainer {
		display: flex;
		gap: .5rem;
	}
}
