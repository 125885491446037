#mainRegion > .externalModules {
	.module {
		flex: 0 0 auto;
		display: flex;
		align-items: baseline;
		gap: .5em;

		min-height: 1em;
		background-color: lavender;
		box-shadow: .2em .2em .3em rgba(black,.2);
		padding: .5em 1em;
		border-radius: .5em;

		margin-top: 1em;

		.nameblock {
			display: flex;
			align-items: center;
			gap: .5em;
		}

		.name {
			flex: 0 0 auto;
			color: black;
		}

		.connectionIndicator {
			color: rgba(black,.2);

			&.connected {
				color: limegreen;
			}

			&.disconnected {
				color: red;
			}
		}

		button {
			font-size: .9em;
			padding: .25em .5em;
		}
	}
}
